  .content-register {
    min-height: 98vh;
    overflow: hidden;
    padding-top: 60px;
    padding-top: 60px;

    &.bg-logo-yellow {
      background: url("../images/png/Background.png") no-repeat center center fixed;
      background-size: cover;
    }

    &.bg-register {
      background: url("../images/png/bg-register.png") no-repeat center center fixed;
      background-size: cover;
    }
  }

  .background-icon-photo {
    content: url("../images/svg/photo.svg");
  }

  .background-icon-north {
    content: url("../images/svg/north.svg");
  }

  .confirm-icon {
    content: url("../images/svg/success.svg");
  }

  .login-background {
    content: url("../images/png/login_background.png");
    background-size: cover;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .confirm-background {
    background-image: linear-gradient(#3a84f3, #f7c1a8);
    padding: 15em 0;
    height: 100vh;
    overflow: hidden;
  }

  .position-relative {
    .material-icons {
      position: absolute;
      top: 6px;
      right: 6px;

      &.left {
        left: 0;
      }
    }
  }

  form {
    label {
      font-size: 1.6em;
      font-family: 'Poppins', sans-serif;
    }
  }

  label,
  p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: $color-black-1;
  }

  label.br,
  span.br {
    &::after {
      content: "\A";
      white-space: pre;
      margin: 0;
    }
  }

  .h1 {
    color: $color-black-1;
    line-height: 40px;
    letter-spacing: 0.1px;
    margin-bottom: 24px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
  }

  .h2 {
    color: $color-black-1;
    line-height: 40px;
    letter-spacing: 0.1px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
  }

  .h5 {
    color: $color-black-1;
  }

  .mt-62 {
    margin-top: 62px;
  }

  .card-body {
    padding: 4em;

    &.disabled {
      pointer-events: none;
      opacity: 0.1;
    }
  }

  @media screen and (min-width: 780px) {
    .form-container {
      margin: 0 8%;
    }
  }