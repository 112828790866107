/* .bs-datepicker */
/* .bs-timepicker */
/* screen size < 1024px */
/* theming */
/*# sourceMappingURL=bs-datepicker.css.map */
.bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: #fff;
  box-shadow: 0 0 10px 0 #aaa;
  position: relative;
  z-index: 1;

  &:after {
    clear: both;
    content: '';
    display: block;
  }

  bs-day-picker {
    float: left;
  }

  button {
    &:hover {
      outline: none;
    }

    &:focus {
      outline: none;
    }

    &:active {
      outline: none;
    }
  }

  input {
    &:hover {
      outline: none;
    }

    &:focus {
      outline: none;
    }

    &:active {
      outline: none;
    }
  }

  .current-timedate {
    color: #54708b;
    font-size: 15px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    border-radius: 20px;
    border: 1px solid #e9edf0;
    margin-bottom: 10px;
    cursor: pointer;
    text-transform: uppercase;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    span {
      &:not(:empty) {
        &:before {
          content: "";
          width: 15px;
          height: 16px;
          display: inline-block;
          margin-right: 4px;
          vertical-align: text-bottom;
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC);
        }
      }
    }
  }

  .bs-media-container {
    display: flex;
  }
}

.bs-datepicker-btns {
  button {
    &:hover {
      outline: none;
    }

    &:focus {
      outline: none;
    }

    &:active {
      outline: none;
    }
  }
}

.bs-datepicker-predefined-btns {
  button {
    &:active {
      outline: none;
    }

    &:focus {
      outline: none;
    }

    width: 100%;
    display: block;
    height: 30px;
    background-color: #9aaec1;
    border-radius: 4px;
    color: #fff;
    border: 0;
    margin-bottom: 10px;
    padding: 0 18px;
    text-align: left;
    transition: 0.3s;

    &:hover {
      background-color: #54708b;
    }
  }
}

.bs-datepicker-head {
  min-width: 270px;
  height: 50px;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  text-align: justify;

  &:after {
    content: "";
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }

  button {
    display: inline-block;
    vertical-align: top;
    padding: 0;
    height: 30px;
    line-height: 30px;
    border: 0;
    background: transparent;
    text-align: center;
    cursor: pointer;
    color: #fff;
    transition: 0.3s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  button[disabled] {
    background: rgba(221, 221, 221, 0.3);
    color: #f5f5f5;
    cursor: not-allowed;

    &:hover {
      background: rgba(221, 221, 221, 0.3);
      color: #f5f5f5;
      cursor: not-allowed;
    }

    &:active {
      background: rgba(221, 221, 221, 0.3);
      color: #f5f5f5;
      cursor: not-allowed;
    }
  }

  button.next {
    border-radius: 50%;
    width: 30px;
    height: 30px;

    span {
      font-size: 28px;
      line-height: 1;
      display: inline-block;
      position: relative;
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }

  button.previous {
    border-radius: 50%;
    width: 30px;
    height: 30px;

    span {
      font-size: 28px;
      line-height: 1;
      display: inline-block;
      position: relative;
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }

  button.current {
    border-radius: 15px;
    max-width: 155px;
    padding: 0 13px;
  }
}

.bs-datepicker-body {
  padding: 10px;
  border-radius: 0 0 3px 3px;
  min-height: 232px;
  min-width: 278px;
  border: 1px solid #e9edf0;

  .days.weeks {
    position: relative;
    z-index: 1;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    th {
      font-size: 13px;
      color: #9aaec1;
      font-weight: 400;
      text-align: center;
    }

    td {
      color: #54708b;
      text-align: center;
      position: relative;
      padding: 0;

      span {
        display: block;
        margin: 0 auto;
        font-size: 13px;
        border-radius: 50%;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;

        &:before {
          content: "";
          display: block;
          position: absolute;
          z-index: -1;
          top: 6px;
          bottom: 6px;
          left: -3px;
          right: -2px;
          box-sizing: content-box;
          background: transparent;
        }
      }

      &:not(.disabled) {
        &:not(.week) {
          span {
            &:not(.disabled) {
              &:not(.is-other-month) {
                cursor: pointer;
              }
            }
          }
        }
      }

      span.is-highlighted {
        &:not(.disabled) {
          &:not(.selected) {
            background-color: #e9edf0;
            transition: 0s;
          }
        }
      }

      span.is-active-other-month {
        &:not(.disabled) {
          &:not(.selected) {
            background-color: #e9edf0;
            transition: 0s;
            cursor: pointer;
          }
        }
      }

      span.disabled {
        color: #9aaec1;
      }

      span.selected {
        color: #fff;
      }

      span.is-other-month {
        color: rgba(0, 0, 0, 0.25);
      }

      span.active.select-start {
        &:after {
          content: "";
          display: block;
          position: absolute;
          z-index: -1;
          width: 100%;
          height: 100%;
          transition: 0.3s;
          top: 0;
          border-radius: 50%;
        }
      }

      span.active.select-end {
        &:after {
          content: "";
          display: block;
          position: absolute;
          z-index: -1;
          width: 100%;
          height: 100%;
          transition: 0.3s;
          top: 0;
          border-radius: 50%;
        }
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 6px;
        bottom: 6px;
        left: -3px;
        right: -2px;
        box-sizing: content-box;
        background: transparent;
      }

      &:last-child.active {
        &:before {
          border-radius: 0 3px 3px 0;
          width: 125%;
          left: -25%;
        }
      }

      span[class*="select-"] {
        border-radius: 50%;
        color: #fff;
      }
    }

    td.is-highlighted {
      &:not(.disabled) {
        &:not(.selected) {
          span {
            background-color: #e9edf0;
            transition: 0s;
          }
        }
      }
    }

    td.is-active-other-month {
      &:not(.disabled) {
        &:not(.selected) {
          span {
            background-color: #e9edf0;
            transition: 0s;
            cursor: pointer;
          }
        }
      }
    }

    td.disabled {
      span {
        color: #9aaec1;
      }
    }

    td.selected {
      span {
        color: #fff;
      }
    }

    td.is-other-month {
      span {
        color: rgba(0, 0, 0, 0.25);
      }
    }

    td.active {
      position: relative;
    }

    td.active.select-start {
      &:before {
        left: 35%;
      }

      span {
        &:after {
          content: "";
          display: block;
          position: absolute;
          z-index: -1;
          width: 100%;
          height: 100%;
          transition: 0.3s;
          top: 0;
          border-radius: 50%;
        }
      }

      + {
        td.active {
          &:before {
            left: -20%;
          }
        }
      }
    }

    td.active.select-end {
      &:before {
        left: -85%;
      }

      span {
        &:after {
          content: "";
          display: block;
          position: absolute;
          z-index: -1;
          width: 100%;
          height: 100%;
          transition: 0.3s;
          top: 0;
          border-radius: 50%;
        }
      }
    }

    td[class*="select-"] {
      span {
        border-radius: 50%;
        color: #fff;
      }
    }

    &:not(.weeks) {
      tr {
        td {
          &:first-child {
            &:before {
              border-radius: 3px 0 0 3px;
            }
          }
        }
      }
    }
  }

  table.days {
    td.active {
      &:not(.select-start) {
        &:before {
          background: #e9edf0;
        }
      }

      + {
        td.is-highlighted {
          &:before {
            background: #e9edf0;
            width: 100%;
          }
        }

        td.select-end {
          &:before {
            background: #e9edf0;
            width: 100%;
          }
        }
      }
    }

    td.in-range {
      &:not(.select-start) {
        &:before {
          background: #e9edf0;
        }
      }

      + {
        td.is-highlighted {
          &:before {
            background: #e9edf0;
            width: 100%;
          }
        }

        td.select-end {
          &:before {
            background: #e9edf0;
            width: 100%;
          }
        }
      }
    }

    span.active {
      &:not(.select-start) {
        &:before {
          background: #e9edf0;
        }
      }
    }

    span.in-range {
      &:not(.select-start) {
        &:before {
          background: #e9edf0;
        }
      }
    }

    span {
      width: 32px;
      height: 32px;
      line-height: 32px;
    }

    span.select-start {
      z-index: 2;
    }

    span.is-highlighted.in-range {
      &:before {
        right: 3px;
        left: 0;
      }
    }

    span.in-range.select-end {
      &:before {
        right: 4px;
        left: 0;
      }
    }

    td.select-start {
      + {
        td.select-end {
          &:before {
            background: #e9edf0;
            width: 100%;
          }
        }

        td.is-highlighted {
          &:before {
            background: #e9edf0;
            width: 100%;
          }
        }
      }
    }
  }

  table.weeks {
    tr {
      td {
        &:nth-child(2).active {
          &:before {
            border-radius: 3px 0 0 3px;
            left: 0;
            width: 100%;
          }
        }
      }
    }
  }

  table.years {
    td {
      span {
        width: 46px;
        height: 46px;
        line-height: 45px;
        margin: 0 auto;
      }
    }

    tr {
      &:not(:last-child) {
        td {
          span {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  table.months {
    td {
      height: 52px;

      span {
        padding: 6px;
        border-radius: 15px;
      }
    }
  }
}

.bs-datepicker-multiple {
  border-radius: 4px 0 0 4px;

  + {
    .bs-datepicker-multiple {
      margin-left: 10px;
    }
  }

  .bs-datepicker {
    box-shadow: none;
    position: relative;

    &:not(:last-child) {
      padding-right: 10px;
    }

    + {
      .bs-datepicker {
        &:after {
          content: "";
          display: block;
          width: 14px;
          height: 10px;
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
          position: absolute;
          top: 25px;
          left: -8px;
        }
      }
    }

    .left {
      float: left;
    }

    .right {
      float: right;
    }
  }
}

.bs-datepicker-container {
  padding: 15px;
}

.bs-datepicker-custom-range {
  padding: 15px;
  background: #eee;
}

.bs-datepicker-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid #e9edf0;

  .btn-default {
    margin-left: 10px;
  }

  .btn-today-wrapper {
    display: flex;
    flex-flow: row wrap;
  }

  .clear-right {
    flex-grow: 0;
  }

  .today-right {
    flex-grow: 0;
  }

  .clear-left {
    flex-grow: 1;
  }

  .today-left {
    flex-grow: 1;
  }

  .clear-center {
    flex-grow: 0.5;
  }

  .today-center {
    flex-grow: 0.5;
  }
}

.bs-timepicker-container {
  padding: 10px 0;
}

.bs-timepicker-label {
  color: #54708b;
  margin-bottom: 10px;
}

.bs-timepicker-controls {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;

  button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 0;
    background-color: #e9edf0;
    color: #54708b;
    font-size: 16px;
    font-weight: 700;
    vertical-align: middle;
    line-height: 0;
    padding: 0;
    transition: 0.3s;

    &:hover {
      background-color: #d5dadd;
    }
  }

  input {
    width: 35px;
    height: 25px;
    border-radius: 13px;
    text-align: center;
    border: 1px solid #e9edf0;
  }
}

.bs-timepicker {
  .switch-time-format {
    text-transform: uppercase;
    min-width: 54px;
    height: 25px;
    border-radius: 20px;
    border: 1px solid #e9edf0;
    background: #fff;
    color: #54708b;
    font-size: 13px;

    img {
      vertical-align: initial;
      margin-left: 4px;
    }
  }
}

bs-datepicker-container {
  z-index: 1080;
}

bs-daterangepicker-container {
  z-index: 1080;
}

.theme-default {
  .bs-datepicker-head {
    background-color: #777;
  }

  .bs-datepicker-predefined-btns {
    button.selected {
      background-color: #777;
    }
  }

  .bs-datepicker-body {
    table {
      td {
        span.selected {
          background-color: #777;
        }

        span[class*="select-"] {
          &:after {
            background-color: #777;
          }
        }
      }

      td.selected {
        span {
          background-color: #777;
        }
      }

      td[class*="select-"] {
        span {
          &:after {
            background-color: #777;
          }
        }
      }

      td.week {
        span {
          color: #777;
        }
      }

      td.active-week {
        span {
          &:hover {
            cursor: pointer;
            background-color: #777;
            color: #fff;
            opacity: 0.5;
            transition: 0s;
          }
        }
      }
    }
  }
}

.theme-green {
  .bs-datepicker-head {
    background-color: #5cb85c;
  }

  .bs-datepicker-predefined-btns {
    button.selected {
      background-color: #5cb85c;
    }
  }

  .bs-datepicker-body {
    table {
      td {
        span.selected {
          background-color: #5cb85c;
        }

        span[class*="select-"] {
          &:after {
            background-color: #5cb85c;
          }
        }
      }

      td.selected {
        span {
          background-color: #5cb85c;
        }
      }

      td[class*="select-"] {
        span {
          &:after {
            background-color: #5cb85c;
          }
        }
      }

      td.week {
        span {
          color: #5cb85c;
        }
      }

      td.active-week {
        span {
          &:hover {
            cursor: pointer;
            background-color: #5cb85c;
            color: #fff;
            opacity: 0.5;
            transition: 0s;
          }
        }
      }
    }
  }
}

.theme-blue {
  .bs-datepicker-head {
    background-color: $contained;
  }

  .bs-datepicker-predefined-btns {
    button.selected {
      background-color: $contained;
    }
  }

  .bs-datepicker-body {
    table {
      td {
        span.selected {
          background-color: $contained;
        }

        span[class*="select-"] {
          &:after {
            background-color: $contained;
          }
        }
      }

      td.selected {
        span {
          background-color: $contained;
        }
      }

      td[class*="select-"] {
        span {
          &:after {
            background-color: $contained;
          }
        }
      }

      td.week {
        span {
          color: $contained;
        }
      }

      td.active-week {
        span {
          &:hover {
            cursor: pointer;
            background-color: $contained;
            color: $color-white;
            opacity: 0.5;
            transition: 0s;
          }
        }
      }
    }
  }
}

.theme-dark-blue {
  .bs-datepicker-head {
    background-color: #337ab7;
  }

  .bs-datepicker-predefined-btns {
    button.selected {
      background-color: #337ab7;
    }
  }

  .bs-datepicker-body {
    table {
      td {
        span.selected {
          background-color: #337ab7;
        }

        span[class*="select-"] {
          &:after {
            background-color: #337ab7;
          }
        }
      }

      td.selected {
        span {
          background-color: #337ab7;
        }
      }

      td[class*="select-"] {
        span {
          &:after {
            background-color: #337ab7;
          }
        }
      }

      td.week {
        span {
          color: #337ab7;
        }
      }

      td.active-week {
        span {
          &:hover {
            cursor: pointer;
            background-color: #337ab7;
            color: #fff;
            opacity: 0.5;
            transition: 0s;
          }
        }
      }
    }
  }
}

.theme-red {
  .bs-datepicker-head {
    background-color: #d9534f;
  }

  .bs-datepicker-predefined-btns {
    button.selected {
      background-color: #d9534f;
    }
  }

  .bs-datepicker-body {
    table {
      td {
        span.selected {
          background-color: #d9534f;
        }

        span[class*="select-"] {
          &:after {
            background-color: #d9534f;
          }
        }
      }

      td.selected {
        span {
          background-color: #d9534f;
        }
      }

      td[class*="select-"] {
        span {
          &:after {
            background-color: #d9534f;
          }
        }
      }

      td.week {
        span {
          color: #d9534f;
        }
      }

      td.active-week {
        span {
          &:hover {
            cursor: pointer;
            background-color: #d9534f;
            color: #fff;
            opacity: 0.5;
            transition: 0s;
          }
        }
      }
    }
  }
}

.theme-orange {
  .bs-datepicker-head {
    background-color: #f0ad4e;
  }

  .bs-datepicker-predefined-btns {
    button.selected {
      background-color: #f0ad4e;
    }
  }

  .bs-datepicker-body {
    table {
      td {
        span.selected {
          background-color: #f0ad4e;
        }

        span[class*="select-"] {
          &:after {
            background-color: #f0ad4e;
          }
        }
      }

      td.selected {
        span {
          background-color: #f0ad4e;
        }
      }

      td[class*="select-"] {
        span {
          &:after {
            background-color: #f0ad4e;
          }
        }
      }

      td.week {
        span {
          color: #f0ad4e;
        }
      }

      td.active-week {
        span {
          &:hover {
            cursor: pointer;
            background-color: #f0ad4e;
            color: #fff;
            opacity: 0.5;
            transition: 0s;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .bs-datepicker {
    .bs-media-container {
      flex-direction: column;
    }
  }

  .bs-datepicker-multiple {
    display: flex;

    + {
      .bs-datepicker-multiple {
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }
}